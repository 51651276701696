import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Inferences from "../components/Inferences";
import ManageDevices from "../components/ManageDevices";
import AlertList from "../components/AlertList/AlertList";

function DashboardScreen() {
  return (
    <div className="dashboard_Launch">
      <Navbar />
      <div className="data">
        <Data />
      </div>
      <div className="inferences">
        <Inferences />
      </div>
      <div className="content-items">
        <div className="managedevices">
          <ManageDevices />
        </div>
        <div className="alertlist">
          <AlertList />
        </div>
      </div>
    </div>
  );
}

export default DashboardScreen;
