import React, { useState, useEffect } from "react";
import "../../components/AlertList/AlertList.css";
import axios from "axios";
import Moment from "react-moment";
import { useMessages } from "../../context/MessageContext";

const URL_BIFROST = process.env.REACT_APP_URL_BIFROST;

Moment.globalFormat = "DD/MM/YYYY HH:mm:ss";

function AlertList() {
  const [alerts, setAlerts] = useState([]);
  const [userId, setUserId] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [isUserIdLoaded, setIsUserIdLoaded] = useState(false);
  const { message } = useMessages();

  useEffect(() => {
    const fetchUserDataFromSession = () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setUserId(userData.id_user);
          setIsUserIdLoaded(true);
        } else {
          console.error("User data not found in session.");
        }
      } catch (error) {
        console.error("Error fetching user data from session:", error);
      }
    };
    fetchUserDataFromSession();
  }, []);

  useEffect(() => {
    const fetchAlerts = async () => {
      if (!isUserIdLoaded) return;

      try {
        const currentPage = parseInt(page, 10);
        const limit = 5;

        const requestUrl = `${URL_BIFROST}/historical_alerts/${userId}?page=${currentPage}&limit=${limit}`;

        const response = await axios.get(requestUrl);
        setAlerts(response.data.data);
        setPagination(response.data.pagination);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAlerts();
  }, [isUserIdLoaded, userId, message, page]);

  const goToPreviousPage = () => {
    setPage(page - 1);
  };
  const goToNextPage = () => {
    setPage(page + 1);
  };

  return (
    <div className="card-alert-list">
      <p>Últimos alertas recebidos</p>
      <table className="alert-list-table">
        <thead>
          <tr>
            <th></th>
            <th>Tipo de alerta</th>
            <th>Data e hora</th>
            <th>Dispositivo</th>
          </tr>
        </thead>
        <tbody>
          {alerts.map((alert, index) => (
            <tr key={index}>
              <td>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    opacity="0.2"
                    d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="#667482"
                  />
                  <path
                    opacity="0.4"
                    d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10Z"
                    fill="#667482"
                  />
                  <rect x="7" y="7" width="6" height="6" rx="3" fill="#7CA5DA" />
                </svg>
              </td>
              <td>{alert.alert_type}</td>
              <td>
                <Moment unix>{alert.timestamp}</Moment>
              </td>
              <td>{alert.device_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="alert-list-navigation">
        <button onClick={goToPreviousPage} disabled={pagination.current_page === 1}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 12H19M5 12L11 6M5 12L11 18"
              stroke="#FFFFFF"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button onClick={goToNextPage} disabled={pagination.current_page === pagination.total_pages}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 12H19M19 12L13 6M19 12L13 18"
              stroke="#FFFFFF"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default AlertList;
