import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.css";
import { generateToken } from "../firebase/firebaseConfig";

const URL_DM = process.env.REACT_APP_URL_DM;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [userToken, setUserToken] = useState("token_not_generated");
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const images = Array.from({ length: 4 }, (_, index) => `/images/backgrounds/background-${index + 1}.png`);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const generatedToken = await generateToken();
        if (generatedToken !== undefined) {
          setUserToken(generatedToken);
          setNotificationEnabled(true);
        }
      } catch (error) {
        console.error("Error generating token:", error);
      }
    };

    fetchToken();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [images.length]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.post(`${URL_DM}/api/login`, {
        deviceID: userToken,
        email: email,
        password: password,
        device_type: "web",
        device_model: "browser",
      });
      const user_data = response.data.user_data;
      user_data["notificationEnabled"] = notificationEnabled;

      if (
        response.data.response === "Login successful" ||
        response.data.response === `Device with id: ${response.data.device.deviceID} create device and login successful`
      ) {
        setIsAuthenticated(true);
        sessionStorage.setItem("user", JSON.stringify(user_data));
        navigate("/dashboard");
        setLoading(false);
        window.location.reload();
      } else {
        setLoading(false);
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.response || "An error occurred during login. Please try again.");
    }
  };

  const bannerStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  if (isAuthenticated) {
    navigate("/dashboard");
    return null;
  }

  return (
    <div className="login">
      <div className="login_Content">
        <div className="login_Form">
          <img src="/images/logo/logo.png" alt="Phone" />
          <form onSubmit={handleSubmit} name='login'>
            {/* <h2>Login</h2> */}
            <label htmlFor="email">E-mail*</label>
            <input
              type="email"
              placeholder="Enter your E-mail"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <label htmlFor="password">Password*</label>
            <input
              type="password"
              placeholder="Enter your password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <div className="checkbox-container">
              <input
                className="login_CheckBox"
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <label className="checkbox-label" htmlFor="rememberMe">
                Remember me for 30 days
              </label>
            </div>
            <button className="submit-btn" type="submit">
              {loading ? <img className="loading-button" src="/loading.svg" alt="loading" /> : "Login"}
            </button>
            {error && <p className="error-message">{error}</p>}
          </form>
          <div className="login_Footer">
            <div className="login_Footer_Content">
              {/* <div className='login_Copy'>
                <p>© SpySkyTech 2024</p>
              </div> */}
              {/* <div className='login_Contact'>
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.33334 2.66666L6.77662 6.47695C7.2174 6.7855 7.43779 6.93977 7.67751 6.99953C7.88927 7.05231 8.11074 7.05231 8.32249 6.99953C8.56222 6.93977 8.78261 6.7855 9.22339 6.47695L14.6667 2.66666M4.53334 11.3333H11.4667C12.5868 11.3333 13.1468 11.3333 13.5747 11.1153C13.951 10.9236 14.2569 10.6176 14.4487 10.2413C14.6667 9.81348 14.6667 9.25343 14.6667 8.13332V3.86666C14.6667 2.74655 14.6667 2.1865 14.4487 1.75868C14.2569 1.38235 13.951 1.07639 13.5747 0.884643C13.1468 0.666656 12.5868 0.666656 11.4667 0.666656H4.53334C3.41323 0.666656 2.85318 0.666656 2.42535 0.884643C2.04903 1.07639 1.74307 1.38235 1.55132 1.75868C1.33334 2.1865 1.33334 2.74655 1.33334 3.86666V8.13332C1.33334 9.25343 1.33334 9.81348 1.55132 10.2413C1.74307 10.6176 2.04903 10.9236 2.42535 11.1153C2.85318 11.3333 3.41323 11.3333 4.53334 11.3333Z" stroke="#667085" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p>contact@spyskytech.com</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="login_Banner" style={bannerStyle}>
          <h1>Bem vindo à SST!</h1>
          <p>
            Somos especialistas em resolver problemas usando inovadoras tecnologias alimentadas por IA para aprimorar o
            seu negócio.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
