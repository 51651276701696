import React from "react";
import Navbar from "../components/Navbar";
import "../components/Reports.css";
import Reports from "../components/Reports";

function ReportsScreen() {
  return (
    <div>
      <Navbar />
      <Reports />
    </div>
  );
}

export default ReportsScreen;
