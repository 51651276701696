import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const Master = () => {
  // Dados estáticos para as categorias
  const data = {
    labels: [
      "Pedestres",
      "Pessoas",
      "Bicicletas",
      "Carros",
      "Vans",
      "Caminhões",
      "Triciclos",
      "Triciclos Cobertos",
      "Ônibus",
      "Motocicletas",
      "Fumaça",
      "Fogo",
    ],
    datasets: [
      {
        label: "Categorias",
        data: [
          15, // Pedestres
          30, // Pessoas
          10, // Bicicletas
          25, // Carros
          5,  // Vans
          8,  // Caminhões
          2,  // Triciclos
          1,  // Triciclos Cobertos
          4,  // Ônibus
          6,  // Motocicletas
          3,  // Fumaça
          1,  // Fogo
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(125, 125, 125, 0.6)",
          "rgba(255, 205, 86, 0.6)",
          "rgba(255, 99, 71, 0.6)",
          "rgba(34, 202, 236, 0.6)",
          "rgba(253, 187, 45, 0.6)",
          "rgba(128, 0, 128, 0.6)",
          "rgba(255, 140, 0, 0.6)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(125, 125, 125, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(255, 99, 71, 1)",
          "rgba(34, 202, 236, 1)",
          "rgba(253, 187, 45, 1)",
          "rgba(128, 0, 128, 1)",
          "rgba(255, 140, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 15,
        },
      },
      title: {
        display: false,
        text: "Distribuição de Categorias",
      },
    },
  };

  return (
    <div
      style={{
        width: "400px",
        height: "400px",
        margin: "auto",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default Master;
