import React, { useState, useEffect } from "react";
import "../components/Data.css";
import { Link } from "react-router-dom";
import MessageAlert from "./MessageAlert/MessageAlert";

function Data() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [, setIdUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  // const [recording, setRecording] = useState(false);

  useEffect(() => {
    const fetchUserDataFromSession = () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setIdUser(userData.id_user);
          setEmail(userData.email);
          setFirstName(userData.user_name);
          setLastName(userData.last_name || ""); // Adjust as necessary
          setProfileImage(userData.user_image);
        } else {
          console.error("User data not found in session.");
        }
      } catch (error) {
        console.error("Error fetching user data from session:", error);
      }
    };

    fetchUserDataFromSession();
  }, []); // Run only once after component mounts

  return (
    <div className="container">
      <div className="data_Profile">
        <div className="data_Name">
          <p>
            {firstName} {lastName}
          </p>
        </div>
        <div className="container_imagem_profile">
          {profileImage ? (
            <img className="profile_Image" src={profileImage} alt="Profile" />
          ) : (
            <svg width="22" height="22" viewBox="0 0 22 22">
              {/* SVG content */}
            </svg>
          )}
        </div>
        <div className="data_Info">
          <p>{email}</p>
        </div>
        <div className="fix_btn">
          <Link to="/profile" className="data_Button">
            Editar perfil
          </Link>
        </div>
      </div>

      <div className="containersofia">
        <div className="view">
          <div className="plane main">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>

      <div className="data_Plan">
        <h3>Plano Enterprise</h3>
        <button className='plan_status'>ATIVO</button>
        <p>Vencimento: 14 dias trial</p>
        <p>Forma de pagamento: Gratuito</p>
        <div className='fix_btn'>
          <div className="plan_Button">Alterar plano</div>
        </div>
      </div>

      <div className="infos">
        <MessageAlert></MessageAlert>
        {/*<ul className='alertas'> 
                    <li className='alerta'>Alerta / Informação 01</li>
                    <li className='alerta'>Alerta / Informação 02</li>
                    <li className='alerta'>Alerta / Informação 03</li>
                </ul>*/}
      </div>
    </div>
  );
}

export default Data;
