/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import "chart.js/auto";
import "../components/Inferences.css";
import Draggable from "react-draggable";
import axios from "axios";
import GrupoEco from "./Charts/GrupoEco/GrupoEco";
import GrupoMonteCarlo from "./Charts/GrupoMonteCarlo/GrupoMonteCarlo";
import GrupoMaster from "./Charts/GrupoMaster/GrupoMaster";
import Condominios from "./Charts/Condominios/Condominios";
import Security from "./Charts/Security/Security";
import Logistica from "./Charts/Logistica/Logistica";
import Medicare from "./Charts/Medicare/Medicare";
import Shopping from "./Charts/Shopping/Shopping";
import Varejo from "./Charts/Varejo/Varejo";
import Agro from "./Charts/Agro/Agro";
import MultiAxis from "./Charts/MultiAxis/MultiAxis";

const URL_BIFROST = process.env.REACT_APP_URL_BIFROST;

function Inferences() {
  const [activeUserId, setActiveUserId] = useState("");
  const [data, setData] = useState({});

  // Dados Mocados Hackaton

  const [selectedGroup, setSelectedGroup] = useState("Grupo0");

  const handleChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const Grupo0 = () => (
    <div>
      <GrupoMaster />
    </div>
  );
  const Grupo1 = () => (
    <div>
      <Security />
    </div>
  );
  const Grupo2 = () => (
    <div>
      <Logistica />
    </div>
  );
  const Grupo3 = () => (
    <div>
      <GrupoEco />
    </div>
  );
  const Grupo4 = () => (
    <div>
      <Medicare />
    </div>
  );
  const Grupo5 = () => (
    <div>
      <GrupoMonteCarlo />
    </div>
  );
  const Grupo6 = () => (
    <div>
      <Condominios />
    </div>
  );
  const Grupo7 = () => (
    <div>
      <Shopping />
    </div>
  );
  const Grupo8 = () => (
    <div>
      <Varejo />
    </div>
  );
  const Grupo9 = () => (
    <div>
      <Agro />
    </div>
  );

  const renderComponent = () => {
    switch (selectedGroup) {
      case "Grupo0":
        return <Grupo0 />;
      case "Grupo1":
        return <Grupo1 />;
      case "Grupo2":
        return <Grupo2 />;
      case "Grupo3":
        return <Grupo3 />;
      case "Grupo4":
        return <Grupo4 />;
      case "Grupo5":
        return <Grupo5 />;
      case "Grupo6":
        return <Grupo6 />;
      case "Grupo7":
        return <Grupo7 />;
      case "Grupo8":
        return <Grupo8 />;
      case "Grupo9":
        return <Grupo9 />;
      default:
        return <Grupo0 />;
    }
  };

  // Fim Dados Mocados

  useEffect(() => {
    const fetchActiveUserId = async () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setActiveUserId(userData.id_user);
        }
      } catch (error) {
        console.error("Error fetching active user ID:", error);
      }
    };

    fetchActiveUserId();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activeUserId) {
          const response = await axios.get(`${URL_BIFROST}/${activeUserId}/grouped_analysis`);
          const responseData = response.data;
          setData(responseData);
        }
      } catch (error) {
        console.error("Error fetching inferences:", error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up an interval to fetch data periodically
    const interval = setInterval(fetchData, 5000); // Fetch every 5 seconds
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [activeUserId]);

  const processChartData = (data) => {
    const chartData = {
      labels: [],
      quantities: [],
    };
    const counts = {};
    data.devices?.forEach((device) => {
      device.object_counts.forEach((countObj) => {
        if (counts[countObj.class_name]) {
          counts[countObj.class_name] += countObj.count;
        } else {
          counts[countObj.class_name] = countObj.count;
        }
      });
    });

    for (const className in counts) {
      chartData.labels.push(className);
      chartData.quantities.push(counts[className]);
    }

    return chartData;
  };

  const chartData = data ? processChartData(data) : { labels: [], quantities: [], confidences: [] };

  const lineChartData = {
    type: "line",
    labels: chartData.labels,
    datasets: [
      {
        label: "",
        data: chartData.quantities,
        backgroundColor: [
          "#fff	",
          "#C71585	",
          "#00BFFF	",
          "#87CEFA	",
          "#FFD700	",
          "#4169E1",
          "#BC8F8F	",
          "#B0C4DE	",
          "#179BAE",
          "#00CED1	",
          "#DAA520	",
          "#BA55D3	",
          "#FFC0CB	",
          "#FFDEAD	",
        ],
        borderWidth: 0,
        pointRadius: 5,
        pointHoverRadius: 8,
      },
    ],
  };

  const LineChartOptions = {
    plugins: {
      legend: {
        display: true,
        labels: {
          generateLabels: (chart) => {
            const data = chart.data;
            return data.datasets
              .map((dataset, i) => {
                if (dataset.label && dataset.label.trim() !== "") {
                  return {
                    text: dataset.label,
                    fillStyle: dataset.backgroundColor[i],
                    hidden: !data.datasets[i].hidden,
                    lineCap: "butt",
                    lineDash: [],
                    lineDashOffset: 0,
                    lineJoin: "miter",
                    strokeStyle: "#fff",
                    pointStyle: "circle",
                    rotation: 0,
                  };
                }
                return null; // Ignora datasets com label vazio
              })
              .filter((item) => item !== null); // Remove itens nulos
          },
        },
      },
      tooltip: {
        enabled: true, // Habilita o tooltip
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw || "";
            return `${label}: ${value}`;
          },
        },
      },
    },

    scales: {
      x: {
        ticks: {
          color: "white",
        },
        grid: {
          color: "rgba(110, 110, 110, 1)",
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          color: "white",
        },
        grid: {
          color: "rgba(110, 110, 110, 1)",
        },
      },
    },
  };

  const pieChartData = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.quantities, // Use quantities for pie chart data
        backgroundColor: [
          "#fff	",
          "#C71585	",
          "#00BFFF	",
          "#87CEFA	",
          "#FFD700	",
          "#4169E1",
          "#BC8F8F	",
          "#B0C4DE	",
          "#179BAE",
          "#00CED1	",
          "#DAA520	",
          "#BA55D3	",
          "#FFC0CB	",
          "#FFDEAD	",
        ],
        borderWidth: 0,
        width: 20,
        height: 20,
      },
    ],
  };
  const pieChartOptions = {
    plugins: {
      legend: {
        position: "right", // Alinha a legenda à direita do gráfico
        labels: {
          font: {
            size: 18, // Tamanho da fonte das legendas
            family: "Arial", // Fonte da legenda (opcional)
          },
          color: "#fff", // Cor da fonte das legendas
          boxWidth: 15, // Largura do quadrado de cor da legenda
          padding: 20, // Espaçamento entre os itens da legenda
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <div className="inferences">
      {data ? (
        <div className="inferences_Content">
          <Draggable>
            <div className="pie_Chart">
              {/* ==== MOCK HACKATON ==== */}

              <div className="infereces_graphics_header">
                <div className="graphics_title">
                  <p>Identificação de Elementos</p>
                </div>
                <div className="group_select">
                  <select value={selectedGroup} onChange={handleChange} name='group_select'>
                    <option value="Grupo0">IA MASTER</option>
                    <option value="Grupo1">Segurança Pública & Segurança Privada</option>
                    <option value="Grupo2">Logística</option>
                    <option value="Grupo3">Rodovias</option>
                    <option value="Grupo4">Hospitais & Saúde</option>
                    <option value="Grupo5">Postos de Combustível</option>
                    <option value="Grupo6">Condomínios</option>
                    <option value="Grupo7">Centros Comerciais & Shoppings</option>
                    <option value="Grupo8">Varejo</option>
                    <option value="Grupo9">Agricultura</option>
                  </select>
                </div>
              </div>

              <div>{renderComponent()}</div>

              {/* ====  FIM MOCK HACKATON ==== */}

              {/* <Doughnut data={pieChartData} options={pieChartOptions} /> */}
            </div>
          </Draggable>
          <Draggable>
            <div className="bar_Chart">
              <div className="graphics_title">
                <p style={{ marginBottom: "4rem" }}>Quantidade de Inferências</p>
              </div>
              <MultiAxis />

              {/* <Line data={lineChartData} options={LineChartOptions} /> */}
            </div>
          </Draggable>
        </div>
      ) : (
        <p>Loading inferences...</p>
      )}
    </div>
  );
}

export default Inferences;
