import React, { useState, useEffect } from "react";
import "./MessageAlert.css";
import { useMessages } from "../../context/MessageContext";

function MessageAlert() {
  const [shownMessage, setShownMessage] = useState({
    title: "",
    body: "",
    time: "",
  });
  const [isNotificationEnabled, setNotificationEnabled] = useState(false);

  const { message } = useMessages();

  useEffect(() => {
    const fetchUserDataFromSession = () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setNotificationEnabled(userData.notificationEnabled);
        } else {
          console.error("User data not found in session.");
        }
      } catch (error) {
        console.error("Error fetching user data from session:", error);
      }
    };
    fetchUserDataFromSession();
  }, []);

  useEffect(() => {
    if (message.title !== undefined) {
      const newMessage = {
        title: message.title,
        body: message.body,
        time: new Date().toLocaleTimeString(),
      };

      setShownMessage(newMessage);
    }
  }, [message]);

  return (
    <>
      {isNotificationEnabled ? (
        <div className="message-container">
          <svg
            width="31"
            height="27"
            viewBox="0 0 31 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.4304 21.1757L18.5572 1.74977C17.8877 0.654144 16.7447 0 15.5 0C14.2551 0 13.1122 0.654144 12.4428 1.74977L0.5696 21.1756C-0.143047 22.3417 -0.190324 23.8199 0.446619 25.0334C1.08356 26.2464 2.30219 27 3.62695 27H27.3731C28.6979 27 29.9165 26.2465 30.5536 25.0331C31.1903 23.8199 31.143 22.3418 30.4304 21.1757ZM15.5 23.7301C14.4081 23.7301 13.5231 22.8059 13.5231 21.6659C13.5231 20.5258 14.4082 19.6016 15.5 19.6016C16.5918 19.6016 17.4768 20.5258 17.4768 21.6659C17.4768 22.8059 16.5917 23.7301 15.5 23.7301ZM18.2401 9.3859L17.2664 16.2311C17.1231 17.2388 16.2246 17.9342 15.2597 17.7846C14.4801 17.6637 13.8956 17.0255 13.7755 16.2545L12.7181 9.42277C12.472 7.83214 13.5073 6.3343 15.0306 6.07726C16.5539 5.82023 17.9884 6.90127 18.2346 8.4919C18.2808 8.79143 18.2793 9.10064 18.2401 9.3859Z"
              fill="#C2C53C"
            />
          </svg>
          <div className="message">
            <p className="message-title">{shownMessage.title}</p>
            <p className="message-body">{shownMessage.body}</p>
            <p className="message-infos">{shownMessage.time}</p>
          </div>
        </div>
      ) : (
        <div className="ntf-disabled-content">
          <h3 className="ntf-disabled-message">
            Para utilizar este recurso, ative as notificações do seu navegador e faça Login novamente.
          </h3>
        </div>
      )}
    </>
  );
}

export default MessageAlert;
